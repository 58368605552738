import {createContext, useContext, useState, useEffect} from "react";
import {firebaseAuth} from "../util/firebase";
import nextApi from "../util/apiConfig";
import {useRouter} from "next/router";

//Default Values
const authContextDefaultValues = {
    user: null,
    loading: true,
};


//Create new Context which holds user data
const AuthContext = createContext(authContextDefaultValues);

//Hook
export function useAuth() {
    return useContext(AuthContext);
}


//user and loading in state, loading true if user not fully loaded
export function AuthProvider({children}) {
    let [user, setUser] = useState({});
    const [loading, setLoading] = useState(true);
    const router = useRouter();

    const formatAuthUser = (user) => {
        return {
            _id: user._id,
            firebase_id: user.firebase_id,
            email: user.email,
            firstname: user.firstname,
            lastname: user.lastname,
            firstLogin: user.firstLogin,
            trialling: new Date(user.trialEnd) > new Date(),
            subscriptionStatus: user.subscriptionStatus || "inactive"
        }
    };

    useEffect(() => {
        const unsubscribe = firebaseAuth.onAuthStateChanged((firebaseUser) => {
            if (firebaseUser) {
                console.log("Auth changed")

                if ((new Date() - new Date(firebaseUser.metadata.creationTime)) < 30000) {
                    //on first login wait a bit until DB is finished creating
                        setTimeout(() => {
                            nextApi.post("/user/find", {filter: {firebase_id: firebaseUser.uid}})
                                .then((user) => {
                                    if (user) {
                                        console.log("found")
                                        // add info that it is the first login
                                        let formattedUser = formatAuthUser({...user.data.message, firstLogin: true});
                                        setUser(formattedUser);
                                        console.log("setting user");
                                        setLoading(false);
                                        if (router.pathname.includes("sign")) {
                                            router.push("/plans", {locale: user.data.message.preferredLanguage});
                                        }
                                    }
                                });
                        }, 3000)
                } else {
                    console.log("Login")
                    nextApi.post("/user/find", {filter: {firebase_id: firebaseUser.uid}})
                        .then((user) => {
                            let formattedUser = formatAuthUser({...user.data.message, firstLogin: false});
                            setUser(formattedUser);
                            setLoading(false);
                            console.log(window.location.pathname)
                            if (window.location.pathname.includes("sign")) {
                                console.log("To Homepage")
                                router.push("/", {locale: user.data.message.preferredLanguage});
                            }else{
                                console.log("Not to home")
                            }
                        });
                }
            } else {
                setUser(null);
                setLoading(false);
            }
        });
        return () => unsubscribe(); // unsubscribing from the listener when the component is unmounting.
    }, []);

    const value = {
        user,
        loading,
    };

    return (
        <>
            <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
        </>
    );
}
