import '../styles/globals.css'
import '../styles/custom.css'
import {AuthProvider} from "../context/AuthContext";
import {appWithTranslation} from 'next-i18next';
import {AuthGuard} from "../components/AuthGuard";
import {useRouter} from "next/router";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";



function MyApp({Component, pageProps}) {
    const getLayout = Component.getLayout || ((page) => page);
    let locale = useRouter().locale
    const initialOptions = {
        "client-id": "ARYDO_tCKNHy6qa7gIvUd-AhVHMbS6PH75OE6sk3RI8MxHe-gQAD0aIHZvNq5C1Ejxu9fkeJbpU73fe9",
        currency: "EUR",
        intent: "subscription",
        vault: true
    };

    return <>
        <AuthProvider>

            {Component.requireAuth ?
                <AuthGuard>
                    <PayPalScriptProvider options={initialOptions}>
                        {getLayout(<Component {...pageProps} locale={locale}/>)}
                    </PayPalScriptProvider>
                </AuthGuard>
                :
                getLayout(<Component {...pageProps} />)}
        </AuthProvider>
    </>
}

export default appWithTranslation(MyApp);
