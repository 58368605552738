import { getApps, initializeApp } from "firebase/app";
import { getAnalytics, isSupported } from "firebase/analytics";
import { getAuth } from "firebase/auth";

const clientCredentials = {
    apiKey: "AIzaSyDw6q4cs9H_P6Cn-MLwxwK889dkgZ2UEv0",
    authDomain: "rescribe-e6a12.firebaseapp.com",
    projectId: "rescribe-e6a12",
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: "1:594663563689:web:e85bbe993b293e7e4364b8",
    measurementId: "G-Y1BG299RQW",
};

const createFirebaseApp = (clientCredentials) => {
    if (getApps.length === 0) {
        initializeApp(clientCredentials);
    }
};

const firebaseApp = createFirebaseApp(clientCredentials);
export const firebaseAuth = getAuth(firebaseApp);
export const firebaseAnalytics = typeof window !== 'undefined' && getAnalytics(firebaseApp);
