import {useAuth} from "../context/AuthContext";
import {useRouter} from "next/router";
import {useEffect} from "react";
import * as animationData from "../public/userloading.json";
import Lottie from "react-lottie";

export function AuthGuard({children}) {
    const {user, loading} = useAuth();
    const router = useRouter();

    useEffect(() => {
        if(!loading){
            //auth is finished but no user
            if(!user){
                router.push("/signin");
            }
        }
    }, [user, loading, router]);

    /* show loading indicator while the auth provider is still initializing */
    if (loading) {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };


        return <div className={"w-screen h-screen flex justify-center items-center"}>
            <Lottie options={defaultOptions}
                    height={200}
                    width={200}
            />
        </div>
    }


    // if auth initialized with a valid user show protected page
    if (!loading && user) {
        return <>{children}</>
    }

    /* otherwise don't return anything, will do a redirect from useEffect */
    return null

}
