import axios from 'axios';
import {btoa} from "next/dist/compiled/@edge-runtime/primitives/encoding";

const nextApi = axios.create({
    baseURL: '/api',
    timeout: 10000,
    headers: {'X-API-KEY': 'f44d8ad31281b8a5a3d87bd7c79ef5252aff9b0bb51b6d0c75c44c154b0604ef'}
});

export const flaskApi = axios.create({
    baseURL: process.env.NODE_ENV === "development" ? 'http://127.0.0.1:5000' : 'https://api.rescribe.ai',
    timeout: 20000,
    headers: {'x-api-key': "f7519900-a373-47c2-aa7f-06423cbcca7e"}
});

export const n8n = axios.create({
    baseURL: 'https://n8n.eisseler.com/webhook',
    timeout: 10000,
    headers: {'n8n-auth-key': "k2Y8CgkpqXwIwOzxh3mw"},
});

export const paypalApi = axios.create({
    baseURL: 'https://api.paypal.com',
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
    },
    auth: {username: "ARYDO_tCKNHy6qa7gIvUd-AhVHMbS6PH75OE6sk3RI8MxHe-gQAD0aIHZvNq5C1Ejxu9fkeJbpU73fe9", password: "ECwkWCk5-YHWSyQ5N1Txp0n_bn9dIz8-6gi9THkOxz2O3d3p4UEC0ejClEeO0euBi9DqZ9QCE7fLWpsA"}
});

export default nextApi;
